import Vue from 'vue'
import App from './App.vue'
import '../plugins/axios'
// router
import router from '../router/routes'

// vuex
import store from "./../Store";


// vuetify
import vuetify from '../plugins/vuetify' // path to vuetify export


// momentjs


import moment from 'moment';
import VueMoment from 'vue-moment';

require('moment/locale/fr');
moment.locale('fr');

Vue.use(VueMoment, { moment });


// vee-lidate https://vee-validate.logaretm.com/v3/
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';


// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('fr', fr);
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);



// layout

import Default from "./layouts/DefaultLayout.vue"
import NoLayout from "./layouts/NoLayout.vue"
import AdminLayout from "./layouts/AdminLayout.vue"
import OperatorLayout from "./layouts/OperatorLayout.vue"

Vue.component('default-layout', Default);
Vue.component('admin-layout', AdminLayout);
Vue.component('no-layout', NoLayout);
Vue.component('operator-layout', OperatorLayout);


Vue.config.productionTip = false

new Vue({
  router, store,
  vuetify,
  render: h => h(App
  ),
}).$mount('#app')

