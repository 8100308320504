import axios from "axios";

const RESSOURCE_NAME = "users";

export default {
  login(user) {
    return axios.post(`${RESSOURCE_NAME}/login`, {
      email: user.email,
      password: user.password,
    });
  },

  forgetPassword(user) {
    return axios.post(`${RESSOURCE_NAME}/lostPassword`, { email: user.email });
  },
  reinitPassword(user) {
    return axios.post(
      `${RESSOURCE_NAME}/reinitPassword/${user.id}/${user.email}`,
      {
        new_password: user.new_password,
        password_confirm: user.password_confirm,
      }
    );
  },
  isAuthenticated() {
    return axios.get(`${RESSOURCE_NAME}/isauthenticated`);
  },
  getAllUsers() {
    return axios.get(`${RESSOURCE_NAME}`);
  },
  createUser(user) {
    console.log(user);
    return axios.post(`${RESSOURCE_NAME}/create`, { user: user });
  },
  getOneUser(id) {
    return axios.get(`${RESSOURCE_NAME}/${id}`);
  },
  updateUser(user, id) {
    return axios.put(`${RESSOURCE_NAME}/${id}`, { user: user });
  },
};
