<template>
  <v-navigation-drawer width="100%" floating permanent class="sidebar">
    <img class="logo" src="@/assets/logo1.png" />
    <h3>Bienvenue {{ $store.state.user.prenom }}</h3>
    <img
      class="sign-out"
      @click="delog"
      src="@/assets/sign_out.png"
      alt="Sign out icon"
    />
    <v-container>
      <v-row v-if="$route.meta.layout == 'admin' && $store.state.user.Role.role == 'administrateur'" no-gutters>
        <v-col align="center" cols="12">
          <v-col
            v-if="active.isActive1 == false"
            @click="activeMenu('isActive1'), $store.commit('dateCreneau', $moment().format('YYYY-MM-DD')  )"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <router-link :to="{ name: 'dashboard' }"
              ><img
                src="@/assets/images/sidebar/accueilOFF.png"
                alt="Logo accueil"
            /></router-link>
          </v-col>
          <v-col
            v-else
            @click="activeMenu('isActive1')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <img src="@/assets/images/sidebar/accueil.png" alt="Logo accueil" />
          </v-col>
          <v-col
            v-if="active.isActive2 == false"
            @click="activeMenu('isActive2')"
            class="ma-0 pa-0 item"
            cols="12"
          >
          <router-link :to="{ name: 'planning_admin' }">
            <img
              src="@/assets/images/sidebar/planningOFF.png"
              alt="Logo planning"
            />
          </router-link>
          </v-col>
          <v-col
            v-else
            @click="activeMenu('isActive2')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <img
              src="@/assets/images/sidebar/planning.png"
              alt="Logo planning"
            />
          </v-col>
          <v-col
            v-if="active.isActive3 == false"
            @click="activeMenu('isActive3')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <router-link :to="{ name: 'clients' }">
              <img
                src="@/assets/images/sidebar/clientOFF.png"
                alt="Logo clients"
              />
            </router-link>
          </v-col>
          <v-col
            v-else
            @click="activeMenu('isActive3')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <img src="@/assets/images/sidebar/clients.png" alt="Logo clients" />
          </v-col>
          <v-col
            v-if="active.isActive4 == false"
            @click="activeMenu('isActive4')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <router-link :to="{ name: 'export' }">
              <img
                src="@/assets/images/sidebar/exportOFF.png"
                alt="Logo export"
              />
            </router-link>
          </v-col>
          <v-col
            v-else
            @click="activeMenu('isActive4')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <img src="@/assets/images/sidebar/export.png" alt="Logo export" />
          </v-col>
          <v-col
            v-if="active.isActive5 == false"
            @click="activeMenu('isActive5')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <router-link :to="{ name: 'administration' }">
              <img
                src="@/assets/images/sidebar/administrationOFF.png"
                alt="Logo administration"
              />
            </router-link>
          </v-col>
          <v-col
            v-else
            @click="activeMenu('isActive5')"
            class="ma-0 pa-0 item"
            cols="12"
            md="12"
            sm="12"
          >
            <img
              src="@/assets/images/sidebar/administration.png"
              alt="Logo administration"
            />
          </v-col>
        </v-col>
      </v-row>
       <v-row v-if="$store.state.user.Role.role === 'CCVE'" no-gutters>
        <v-col align="center" cols="12">
          
          <v-col
            v-if="active.isActive4 == false"
            @click="activeMenu('isActive4')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <router-link :to="{ name: 'export_ccve' }">
              <img
                src="@/assets/images/sidebar/exportOFF.png"
                alt="Logo export"
              />
            </router-link>
          </v-col>
          <v-col
            v-else
            @click="activeMenu('isActive4')"
            class="ma-0 pa-0 item"
            cols="12"
          >
            <img src="@/assets/images/sidebar/export.png" alt="Logo export" />
          </v-col>
          
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      active: {
        isActive1: true,
        isActive2: false,
        isActive3: false,
        isActive4: false,
        isActive5: false,
      },
    };
  },

  methods: {
    delog() {
      localStorage.clear();
      this.$store.commit("resetState");
      this.$router.push({ name: "login" });
    },
    activeMenu(menu) {
      for (let v in this.active) {
        if (v == menu) {
          this.active[v] = true;
        } else {
          this.active[v] = false;
        }
      }
    },
  },
  created() {
    if (this.$route.name == "dashboard") {
      this.active = {
        isActive1: true,
        isActive2: false,
        isActive3: false,
        isActive4: false,
        isActive5: false,
      };
    } else if (this.$route.name == "clients") {
      this.active = {
        isActive1: false,
        isActive2: false,
        isActive3: true,
        isActive4: false,
        isActive5: false,
      };
    } else if (this.$route.name == "administration") {
      this.active = {
        isActive1: false,
        isActive2: false,
        isActive3: false,
        isActive4: false,
        isActive5: true,
      };
    }
  },
};
</script>

<style scoped>
.sidebar .logo {
  width: 70px;
  margin-top: 50px;
}

.sidebar h3 {
  color: #93b121;
  font-weight: bold;
  font-size: 16px;
}

.sidebar .sign-out {
  cursor: pointer;
  width: 15px;
}
.item img {
  width: 100%;
}
.continer {
  padding: 20px;
}

@media screen and (max-width: 1500px) {
}
</style>
