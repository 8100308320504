<template>
  <v-app id="app">
    <component :is="layout">
      <v-main>
        <v-container
          id="route"
          fluid
          class="px-0 py-0"
          :key="index"
          :class="{ login: $route.name == 'login' }"
        >
          <router-view :key="$route.fullPath" />
        </v-container>
      </v-main>
    </component>
  </v-app>
</template>

<script>
const defautl_layout = "default";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defautl_layout) + "-layout";
    },
  },
  methods: {
    rechargeComponent: function() {
      return (this.index += 1);
    },
  },
  data() {
    return {
      index: 0,
    };
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
  background-color: #f1f1f1;
  margin-top: 0px;
}
.v-snack {
  position: relative !important;
  height: 100% !important;
}
#snack {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}
/* #route {
  min-height: 100vh;
  height: 100%;
} */

</style>
