import axios from "axios";

const RESSOURCE_NAME = "clients";
const token = localStorage.getItem("user-token");
axios.defaults.headers.common = { Authorization: `bearer ${token}` };

export default {
  getClientInfos(id_client) {
    return axios.get(`${RESSOURCE_NAME}/${id_client}`);
  },

  getChiffres() {
    return axios.get(`${RESSOURCE_NAME}/chiffres`);
  },

  getClients(query = "", displayItem, page = 0, column = "nom", order) {
    return axios.post(`${RESSOURCE_NAME}/?page=` + page + `&size=` + displayItem + `&orderBy=` + column + `&sortBy=` + order + ``, { query: query })
  },

  call(data) {
    return axios.post(`${RESSOURCE_NAME}/appel`, {
      appel_entrant: data.appel_entrant,
      marche: data.marche,
      // chanel:data.chanel,
      // username:data.username
    });
  },

  blacklist(id_client, commentaire) {
    return axios.post(`${RESSOURCE_NAME}/blacklist/${id_client}`, {
      commentaire_blacklist: commentaire,
    });
  },
  nouveauCommentaire(id, commentaire) {
    return axios.post(`${RESSOURCE_NAME}/${id}/addcomment`, {
      commentaire: commentaire,
    });
  },
  nouveauClient(client) {
    console.log(client, "client create in api");
    return axios.post(`${RESSOURCE_NAME}/addclient`, {"client":client});
  },

  updateClient(client) {
    console.log(client, "client update in api",client.id_client);
     return axios.post(`${RESSOURCE_NAME}/updateclient/${client.id_client}`, {"client":client});
  },

  commentaires(client, page) {
    console.log(client, "blabla commentaire");
     return axios.post(`${RESSOURCE_NAME}/${client}/commentaires/?page=${page}&size=5`, {"client":client, "page":page}, );
  },
  desactiveClient(id) {
    return axios.get(`${RESSOURCE_NAME}/active/${id}`)
  }
};
