<template>

<v-main full-width class="chiffresClients">
  <v-row class="pt-3" v-for="(chiffre) in chiffres" :key="chiffre.mois">
    <v-col cols="12" >
      <v-card width="400px" class="bloc-chiffres" >
        <v-row>
          <v-col cols="12" >
            <p class="mois ml-7 pt-2"> {{chiffre.mois}} </p>
             <div class="line ml-7"></div>
          </v-col>

        </v-row>

        <v-row>
          <v-col cols="12" class="mb-5" >
            <p class="collectes ml-7"> {{chiffre.count}} COLLECTES </p>
            <p class="poids-volume ml-7 pt-2"> {{chiffre.volume}} m&#179; collect&eacute;s </p>
            <p class="poids-volume ml-7 pt-2"> {{chiffre.poids}} tonnes collect&eacute;es </p>
          </v-col>
        </v-row>
<!-- 
        <v-row>
          <v-col cols="12" >
            <p class="mois ml-7 mt-0 mb-0"> {{chiffre.volume}} m collect&eacute;s </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" >
            <p class="mois ml-7"> {{chiffre.poids}} tonnes collect&eacute;es </p>
          </v-col>
        </v-row> -->

      </v-card>
    </v-col>
  </v-row>
</v-main>

  
</template>

<script>
import apiClients from "@/api/clients";

export default {
  name: "chiffresClients",

  data() {
    return {
      chiffres: [],
    };
  },

  components: {
  },

  // props: {
  //   title: {
  //     type: String,
  //     default: () => "",
  //   },
  // },

  async created() {
    this.getChiffres();
  },
  methods: {
    getChiffres() {
      apiClients.getChiffres().then((res) => {
        this.chiffres.push(res.data.previousMonth),
        this.chiffres.push(res.data.currentMonth),
        this.chiffres.push(res.data.nextMonth)
        console.log(this.chiffres)
      });
    },
  },


};
</script>

<style scoped>
.chiffresClients {
  margin-top: 10px;
}

.bloc-chiffres {
  background-color: #002B47;
  border-radius: 15px;
  opacity: 1;
  margin: -10px 0;
  padding: 0;
  height: auto;
}

.mois {
  text-align: left;
  color: #fff !important;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal bold 0.92rem Roboto;
  margin-bottom: 0px;
}

.collectes {
  text-align: left;
  color: #fff !important;
  text-transform: uppercase;
  opacity: 1;
  font: normal normal bold 2.57142857rem Roboto;
  margin-bottom: 0px;
}

.poids-volume {
  text-align: left;
  color: #fff !important;
  opacity: 1;
  font: normal normal bold 1.28rem Roboto;
  margin-bottom: 0px;
}

.line {
  text-align: left;
  padding: 2px 0;
  width: 7%;
  background-color: #af2c70;
  align-self: center;
  margin-top: 1vh;
  margin-bottom: -2vh;
}

@media screen and (max-width: 810px) {
    .collectes {
    font: normal normal bold 1.8rem Roboto;

  }

}
</style>